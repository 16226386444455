<template>
  <!--  工人维度--施工工地整洁度变化 -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
    ></s-search>
    <!-- <div class="table-button">
      <a-button type="plain" icon="redo">刷新</a-button>
    </div> -->
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetAlarmEvent } from "@/api/image";

// 搜索条件
const queryItems = [
  {
    label: "姓名",
    // value: "w_event_name",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    // value: "w_event_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "时间段",
    type: "dateRange",
    range: ["a_time_start", "a_time_end"],
    keyType: "",
    defaultValue: [],
  },
  {
    label: "工种",
    // value: "w_event_name",
    type: "select",
    selectOption: "WorkType",
  },
  {
    label: "工地名称",
    // value: "w_event_name",
    type: "input",
    defaultValue: "",
  },
];

const orderParam = [
  "bc_name",
  "ws_name",
  "e_events",
  "eventworker_type",
  "eventworker_post",
  "ws_gj_name",
  "ws_jl_name",
  "e_time_start",
  "e_time_end",
  "w_event_name",
  "pageNo",
  "pageSize",
];

export default {
  name: "CleanChange",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      isShowBtns: [],
    };
  },
  created() {
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.pageNo = parameter.pageNo;
        this.pageSize = parameter.pageSize;
      } else {
      }
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetAlarmEvent(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;

        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },

    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
  },
};
</script>
<style lang="less" scoped>
</style>